import $ from "jquery"

export function formatBytes(bytes, decimals) {
   if(bytes == 0) return '0 Bytes';
   var k = 1024,
       dm = decimals || 2,
       sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
       i = Math.floor(Math.log(bytes) / Math.log(k));
   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export function formatBits(bits, decimals) {
   if(bits == 0) return '0 b';
   var k = 1024,
       dm = decimals || 2,
       sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'],
       i = Math.floor(Math.log(bits) / Math.log(k));
   return parseFloat((bits / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

$(function(){
  //freeze header
  $("table.table:not(.no-freezeheader)").freezeHeader({'offset': '50px'});
  $(document).ajaxSuccess(function() {
    setTimeout(function(){ $("table.table:not(.no-freezeheader)").freezeHeader({'offset': '50px'}); }, 50);
  });

  //ajax buttons loading visualization
  $('a.btn[data-remote="true"]').bind('ajax:beforeSend', function(event) {
    $(event.target).addClass("disabled");
  });
  $('a.btn[data-remote="true"]').bind('ajax:complete', function(event) {
    $(event.target).removeClass("disabled");
  });

  //minicolors
  $('[data-minicolors]').each(function(i, elem) {
    var input = $(this);
    input.minicolors(input.data('minicolors'));
  });
});

/* minicolors for simple_form
$(document).on(window.Turbolinks ? 'turbolinks:load' : 'ready', function() {
  $('[data-minicolors]').each(function(i, elem) {
    var input = $(this);
    input.minicolors(input.data('minicolors'));
  });
});*/

export function remove_fields(link) {
  $(link).parent().find("._destroy_field").val("1"); //prev can't be used, it's not working all the time
  $(link).closest(".fields").hide();
  var current = $(link).closest(".fields");
  while((current = current.next(".adjacentfields")).length > 0) current.hide();
}

export function add_fields(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g")
  $(link).parent().before(content.replace(regexp, new_id));
}

export function append_fields(link, association, content, wrapper) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g")
  var wrp = $(wrapper);
  if(wrp.is("table")) {
    if(wrp.find("tbody").length == 0) wrp.append("<tbody></tbody>");
    else if(!wrp.find("tbody").hasClass("fields")) wrp = wrp.find("tbody");
  }
  wrp.append(content.replace(regexp, new_id));
}

var original_location = {url: window.location.protocol + "//" + window.location.host + window.location.pathname, path: window.location.pathname};
var search_timeout = null;
var search_instance = null;
export function send_search() {
  console.log("send_search");
  if(search_timeout) {
    clearTimeout(search_timeout);
    search_timeout = null;
  }
  var container = $("form#searcher");
  container.find('.pagination_info, .signalizer').html("<i class='fa-solid fa-circle-notch fa-spin'></i>");
  search_timeout = setTimeout(function() {
    var instance = new Date().getTime();
    search_instance = instance;
    var url = container.attr("action");
    url += url.indexOf('?') > -1 ? "&" : "?";
    url += container.serialize();
    var ajax = $.ajax({
      url: url,
      success: function(text) {//execute script here
        if(search_instance == instance) {
          $.globalEval(text);
          $(container).trigger("searcher:searched");
        }
      },
      dataType: "script",
      converters: {
        'text script': function(text) {return text;} //disables executing the script by jquery
      }
    });
    if(!container.data("inner") && history.replaceState && (url.indexOf(original_location.url) == 0 || url.indexOf(original_location.path) == 0)) history.replaceState(null, document.title, url);
  }, 300);
  return false;
}
var quick_search_timeout = null;
var quick_search_instance = null;
function send_quick_search() {
  console.log("send_quick_search");
  if(quick_search_timeout) {
    clearTimeout(quick_search_timeout);
    quick_search_timeout = null;
  }
  $('#quick-searcher .signalizer').html("<i class='fa-solid fa-circle-notch fa-spin'></i>");
  quick_search_timeout = setTimeout(function() {
    var instance = new Date().getTime();
    quick_search_instance = instance;
    $.ajax({
      url: $("#quick-searcher").attr("action"),
      data: $("#quick-searcher").serialize(),
      success: function(text) {//execute script here
        if(quick_search_instance == instance) $.globalEval(text);
      },
      dataType: "script",
      converters: {
        'text script': function(text) {return text;} //disables executing the script by jquery
      }
    });
  }, 300);
  return false;
}

//console.log("running app");
$(function() {
  //console.log("ready");
  $('#searcher, .inner-searcher').tableSelect();
  $('#searcher, .inner-searcher').on('click', 'th a:not(.no-searcher), .pagination li:not(.disabled) a, .button_tabs a:not(.disabled)', function(event) {
    console.log("sorting or paging link clicked");
    var container = $(event.target).closest("#searcher, .inner-searcher");
    $.getScript(this.href, function() {
      var scrollTop = $(document).scrollTop();
      var viewTop = $(container).offset().top -50; //-50 for topbar
      if(viewTop < scrollTop) $('html,body').stop().animate({scrollTop: viewTop}, 0);
      $(container).trigger("searcher:browsed");
    });
    var pagination_info = $(this).closest('#searcher, .inner-searcher').find('.pagination_info');
    pagination_info.width(pagination_info.width());
    pagination_info.html("<i class='fa-solid fa-circle-notch fa-spin'></i>");
    if($(event.target).closest("#searcher").length > 0 && history.replaceState && (this.href.indexOf(original_location.url) == 0 || this.href.indexOf(original_location.path) == 0)) history.replaceState(null, document.title, this.href);
    return false;
  });
  $('#searcher, .inner-searcher').on('click', '.pagination li.disabled a', function() {
    return false;
  });
  $('form#searcher input').keyup(function(event) {
    if(event.keyCode == 8 || event.keyCode > 32 && String.fromCharCode(event.keyCode).match(/^\S+$/i)) send_search();
  });
  //$('form#searcher input').bind('changeDate', send_search);
  $('form#searcher select').bind('change', send_search);

  // QUICK SEARCH
  $('#quick-searcher input').keyup(function() {
    if(event.keyCode == 38) {
      var item = $('#quick-search-results li a.active').get(0);
      if(!item) return $('#quick-search-results li:first-child a').addClass("active");
      var prev = $(item).parent().prev().find("a").get(0);
      if(prev) {
        $(prev).parent().siblings().find('a').removeClass('active');
        $(prev).addClass('active');
      }
    } else
    if(event.keyCode == 40) {
      var item = $('#quick-search-results li a.active').get(0);
      if(!item) return $('#quick-search-results li:first-child a').addClass("active");
      var next = $(item).parent().next().find("a").get(0);
      if(next) {
        $(next).parent().siblings().find('a').removeClass('active');
        $(next).addClass('active');
      }
    } else
    if(event.keyCode == 8 || event.keyCode > 32 && String.fromCharCode(event.keyCode).match(/^\S+$/i)) send_quick_search();
  });
  $('#quick-searcher input').focus(function() {
    $('#quick-searcher input').addClass("focused");
  });
  $('#quick-searcher input').blur(function() {
    setTimeout(function() {$('#quick-searcher input').removeClass("focused");}, 300);
  });
  $('#quick-searcher').submit(function() {
    var item = $('#quick-search-results li a.active').get(0);
    if(!item) return false;
    window.location.href = item.href;
    return false;
  });
});
export function quick_search_item_mouseover(link) {
  $(link).parent().siblings().find('a').removeClass('active');
  $(link).addClass('active');
}

//device registration
export function reset_registration_key_result() {
  $("#unregistered_devices").html("");
  $(".device_local_net_select").empty();
  $(".device_castproxy_mode").val("").change();
}
var registration_key_interval = false;
function send_registration_key_search() {
  var input = $('input#registration_key_field');
  $.get(input.data("search-url"), {registration_key: input.val(), device_type: input.data("device-type"), no_buttons: 1}, null, "script");
}
var registration_key_last_checked = "";
function check_registration_key_search() {
  var input = $('input#registration_key_field');
  if(input.length == 0) return;
  if(input.val() == registration_key_last_checked) return;
  registration_key_last_checked = input.val();

  if(registration_key_interval !== false) {
    clearInterval(registration_key_interval);
    registration_key_interval = false;
  }

  if(input.val().replace(/[\s\.\-:]/g, '').match(/^.{20}$/i)) {
    $("#unregistered_devices").html("<i class='fa-solid fa-circle-notch fa-spin'></i> Loading...");
    send_registration_key_search();
    registration_key_interval = setInterval(send_registration_key_search, 10*1000);
  } else {
    reset_registration_key_result();
  }
}
$(function() {
  check_registration_key_search();
  $('input#registration_key_field').keyup(function(event) {
    check_registration_key_search();
  }).blur(function() {
    check_registration_key_search();
  });
});

// price categories
export function price_category_show_final_price(decimal_places) {
  var price = parseFloat($("#price_category_price").val()) || 0.0;
  var vat = parseFloat($("#price_category_vat").val()) || 0.0;
  $("#price_category_price_inc_vat").val(Math.round(price * (100.0 + vat)/100.0*Math.pow(10, decimal_places))/parseFloat(Math.pow(10,decimal_places)));
}

//search autocomplete
var search_autocomplete_cache = {};
export function init_search_autocomplete(element) {
  element.find("input[data-searchautocomplete]").each(function(index) {
    var searchInput = $(this);
    var idInput = searchInput.siblings("input");
    if(idInput.length == 0) {
      console.log("Could not find id input");
      return;
    }
    var url = $(this).data("searchautocomplete");
    $(this).data('selected', $(this).val());
    $(this).autocomplete({
      minLength: 0,
      source: function(request, response) {
        var filled_url = url.replace(/__(.+?)__/g, function(match, p1) { return $('#'+p1).val(); });
        var cache_key = filled_url + "?" + request.term;
        if(cache_key in search_autocomplete_cache) {
          response(search_autocomplete_cache[cache_key]);
          return;
        }
        $.getJSON(filled_url, { query: request.term, "_": new Date().getTime() }, function(data) {
          search_autocomplete_cache[cache_key] = data;
          response(data);
        });
      },
      focus: function(event, ui) {
        searchInput.val(ui.item.label);
        return false;
      },
      select: function(event, ui) {
        idInput.val(ui.item.value);
        searchInput.val(ui.item.label);
        searchInput.data('selected', ui.item.label);
        return false;
      }
    }).focus(function () {
      $(this).autocomplete("search");
    }).blur(function () {
      if($(this).val().trim() == "") {
        idInput.val('');
        searchInput.val('');
        searchInput.data('selected', '');
      } else $(this).val($(this).data('selected'));
    }).autocomplete("instance")._renderItem = function(ul, item) {
      return $('<li class="ui-menu-item">').append('<div tabindex="-1" class="ui-menu-item-wrapper">' + item.label_html + '</div>').appendTo(ul);
    };
  });
}
$(function() {
  init_search_autocomplete($('body'));
});

//search timezone
var search_autocomplete_cache = {};
export function init_search_timezone(element) {
  element.find("input[data-searchtimezone]").each(function(index) {
    var searchInput = $(this);
    $(this).data('selected', $(this).val());
    $(this).autocomplete({
      minLength: 0,
      delay: 100,
      source: searchtimezone_data,
      focus: function(event, ui) {
        searchInput.val(ui.item.value);
        return false;
      },
      select: function(event, ui) {
        searchInput.val(ui.item.value);
        searchInput.data('selected', ui.item.value);
        return false;
      }
    }).focus(function () {
      $(this).autocomplete("search");
    }).blur(function () {
      if($(this).val().trim() == "") {
        searchInput.val('');
        searchInput.data('selected', '');
      } else $(this).val($(this).data('selected'));
    }).autocomplete("instance")._renderItem = function(ul, item) {
      return $('<li class="ui-menu-item">').append('<div tabindex="-1" class="ui-menu-item-wrapper">' + item.label + '</div>').appendTo(ul);
    };
  });
}
$(function() {
  init_search_timezone($('body'));
});
